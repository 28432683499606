import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import './styles.scss';

// NotFound is a 404 page for unmatched routes
function NotFound() {
  return (
    <>
      <Helmet title="Page not found | BBC RemArc" />
      <div className="not-found-bg">
        <div className="not-found-text">
          <div className="row">
            <h1>
              You seem to have requested a page that doesn&apos;t exist.
            </h1>
          </div>
          <br />
          <div className="row not-found-link">
            <Link to="/"><h2>Go back to the start of RemArc</h2></Link>
          </div>
        </div>
      </div>
    </>
  );
}

export default NotFound;
