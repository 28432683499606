import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import PropTypes from 'prop-types';
import modalText from './modalText';
import GelIcon from '../../Components/GelIcon';
import { useAppContext } from '../../contexts/AppContext';

import './styles.scss';

function InfoAboutModal({ modalType }) {
  const { toggleInfoAboutModal } = useAppContext();

  const handleCloseModal = () => {
    if (modalType === 'info') {
      window.localStorage.setItem('remarc-loaded', true);
    }
    toggleInfoAboutModal(false);
  };

  return (
    <Modal id="info-about-modal" size="lg" show onHide={handleCloseModal}>
      <Modal.Header closeButton>
        <Modal.Title dangerouslySetInnerHTML={{ __html: modalText[modalType].title }} />
      </Modal.Header>
      <Modal.Body>
        <small dangerouslySetInnerHTML={{ __html: modalText[modalType].body }} />
      </Modal.Body>
      <Modal.Footer>
        <div className="footer-center">
          <Button variant="default" onClick={handleCloseModal}>
            {modalText[modalType].buttonText}
          </Button>
        </div>
        <div className="footer-side">
          {modalText[modalType].deliveryDocument && (
            <>
              <GelIcon name="info" fill="grey" size={20} />&nbsp;
              <a
                href={`/${modalText[modalType].deliveryDocument}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <strong>{modalText[modalType].deliveryButtonText}</strong>
              </a>
            </>
          )}
        </div>
      </Modal.Footer>
    </Modal>
  );
}

InfoAboutModal.propTypes = {
  modalType: PropTypes.string.isRequired
}

export default InfoAboutModal;
