import React from 'react';
import PropTypes from 'prop-types';

// eslint-disable-next-line consistent-return
const getIcon = (name) => {
  if (name === 'next') {
    return <path d="M29 16L3 0v7.2L17.6 16 3 24.8V32" />;
  }
  if (name === 'prev') {
    return <path d="M3 16l26 16v-7.2L14.4 16 29 7.2V0" />;
  }
  if (name === 'play' || name === 'video') {
    return <path d="M3 32l26-16L3 0" />;
  }
  if (name === 'pause') {
    return <path d="M3 0h9v32H3zm17 0h9v32h-9z" />;
  }
  if (name === 'audio') {
    return <><path d="M21.4 4.8L20 6.2c2.5 2.5 4 6 4 9.8s-1.5 7.3-4 9.8l1.4 1.4C24.2 24.3 26 20.4 26 16c0-4.4-1.8-8.3-4.6-11.2z" /><path d="M25.6.5L24.2 2c3.6 3.6 5.8 8.6 5.8 14s-2.2 10.4-5.8 14l1.4 1.4C29.6 27.5 32 22 32 16S29.6 4.5 25.6.5zM16 4l-6 6H0v12h10l6 6" /></>;
  }
  if (name === 'image') {
    return <><path d="M9 5V3H4v2H0v24h32V5H9zm-2.5 8C5.1 13 4 11.9 4 10.5S5.1 8 6.5 8 9 9.1 9 10.5 7.9 13 6.5 13zM20 26c-5 0-9-4-9-9s4-9 9-9 9 4 9 9-4 9-9 9z" /><path d="M20 11.5c-3 0-5.5 2.5-5.5 5.5s2.5 5.5 5.5 5.5 5.5-2.5 5.5-5.5-2.5-5.5-5.5-5.5z" /></>;
  }
  if (name === 'favourite') {
    return <path d="M29.4 4.3c-3.5-3.5-9-3.5-12.5 0-.3.4-.6.7-.9 1.1-.3-.4-.6-.7-.9-1.1-3.5-3.5-9-3.5-12.5 0s-3.5 9 0 12.5L16 30.2l13.4-13.4c3.5-3.4 3.5-9 0-12.5z" />;
  }
  if (name === 'info') {
    return <path d="M16 0C7.2 0 0 7.2 0 16s7.2 16 16 16 16-7.2 16-16S24.8 0 16 0zm2 25h-4V13h4v12zm0-14h-4V7h4v4z" />;
  }
  if (name === 'download') {
    return <path d="M28.2 12.2L19 21.4V0h-6v21.4l-9.2-9.2L0 16l14 14H2v-6H0v8h16l16-16m-2 14h-8l-2 2h12v-8h-2" />;
  }
  if (name === 'loading') {
    return <path d="M29.8 8l-3.5 2c1 1.8 1.6 3.8 1.6 6 0 6.6-5.4 12-12 12S4 22.6 4 16 9.4 4 16 4V0C7.2 0 0 7.2 0 16s7.2 16 16 16 16-7.2 16-16c0-2.9-.8-5.6-2.2-8z" />;
  }
  if (name === 'replay') {
    return <><path d="M25 18.5c0 5.2-4.3 9.5-9.5 9.5S6 23.7 6 18.5 10.3 9 15.5 9h.5V5h-.5C8 5 2 11 2 18.5S8 32 15.5 32 29 26 29 18.5V18h-4v.5z" /><path d="M18 0v14l12-7" /></>;
  }
  if (name === 'refresh') {
    return <path d="M32 0H20v12h4V7.1c2.4 2.2 4 5.4 4 8.9 0 6.6-5.4 12-12 12v4c8.8 0 16-7.2 16-16 0-4.8-2.1-9.1-5.4-12H32V0zM8 24.9c-2.4-2.2-4-5.4-4-8.9C4 9.4 9.4 4 16 4V0C7.2 0 0 7.2 0 16c0 4.8 2.1 9.1 5.4 12H0v4h12V20H8v4.9z" />;
  }
  if (name === 'back-to-start') {
    return <path d="M2 0v32h3V0m25 0L5 16l25 16v-8l-13.3-8L30 8"></path>
  }
};

function GelIcon({ size, fill, name }) {
  return (
    <svg viewBox="0 0 32 32" style={{ width: size, height: size, fill }}>
      {getIcon(name)}
    </svg>
  );
}

GelIcon.propTypes = {
  size: PropTypes.number,
  fill: PropTypes.string,
  name: PropTypes.string.isRequired,
};

GelIcon.defaultProps = {
  size: 20,
  fill: 'white',
};

export default GelIcon;
