import React from 'react';
import { withRouter, Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useAppContext } from '../../contexts/AppContext';

import {
  Container, Row, Col,
} from 'react-bootstrap';

import GelIcon from '../GelIcon';

function FormatSelector({ history }) {
  const { state: { theme, decade } } = useAppContext();

  const mode = history.location.pathname.replace(/\//g, '');

  // Get the modeValue based on if theme or decade was selected
  const modeValue = mode === 'theme' ? theme : decade;

  return (
    <Container fluid className="pb-5">
      <Row>
        <Col xs={12} sm={12} md={4} lg={4} xl={4}>
          <Link
            className="btn-theme-link"
            to={`/${mode}/${modeValue.toLowerCase()}/images`}
          >
            <div className="btn-theme">
              <GelIcon name="image" size={80} />
              <br />
              Images
            </div>
          </Link>

        </Col>
        <Col xs={12} sm={12} md={4} lg={4} xl={4}>
          <Link
            className="btn-theme-link"
            to={`/${mode}/${modeValue.toLowerCase()}/audio`}
          >
            <div className="btn-theme">
              <GelIcon name="audio" size={80} />
              <br />
              Audio
            </div>
          </Link>
        </Col>
        <Col xs={12} sm={12} md={4} lg={4} xl={4}>
          <Link
            className="btn-theme-link"
            to={`/${mode}/${modeValue.toLowerCase()}/video`}
          >
            <div className="btn-theme">
              <GelIcon name="video" size={80} />
              <br />
              Video
            </div>
          </Link>
        </Col>
      </Row>
    </Container>
  );
}

FormatSelector.propTypes = {
  history: PropTypes.shape({
    location: PropTypes.shape({
      pathname: PropTypes.string
    })
  })
};

export default withRouter(FormatSelector);
