import React from 'react';
import {
  Container, Col, Row, Button,
} from 'react-bootstrap';
import FormatSelector from '../../Components/FormatSelector/FormatSelector';
import { useAppContext } from '../../contexts/AppContext';

import './styles.scss';

const THEMES = [
  'Animals',
  'Childhood',
  'Events',
  'Leisure',
  'Music',
  'People',
  'Sport',
  'TV and Radio',
];

function ThemeSelection() {
  const { state: { theme }, setTheme } = useAppContext();

  const handleSelection = (selection) => {
    setTheme(selection);

    setTimeout(() => {
      document.getElementById("theme-format-selector").scrollIntoView({
        behavior: 'smooth',
        block: 'end'
      });
    }, 100);
  };

  return (
    <Container fluid role="main">
      <div className="theme-bg-overlay" />
      <Row className="page-title">
        <h2>Theme</h2>
      </Row>
      <Row>
        {
          THEMES.map((thisTheme) => (
            <Col xs={12} sm={6} md={6} lg={3} xl={3} key={thisTheme} >
              <Button className="btn-theme btn-default" onClick={() => handleSelection(thisTheme)}>
                <span>{thisTheme}</span>
              </Button>
            </Col>
          ))
        }
      </Row>
      <Row className="justify-content-md-center">
        <Col xs={12} sm={6} md={6} lg={3} xl={3}>
          <Button className="btn-theme btn-default" onClick={() => handleSelection('favourites')}>
            <span>Favourites</span>
          </Button>
        </Col>
        <Col xs={12} sm={6} md={6} lg={3} xl={3}>
          <Button className="btn-theme btn-back btn-default" href="/">
            <span>Back</span>
          </Button>
        </Col>
      </Row>
      <br />
      <div id="theme-format-selector">
        {
          theme && (
            <FormatSelector />
          )
        }
      </div>
    </Container>
  );
}

export default ThemeSelection;
