import React from 'react';
import { Link } from 'react-router-dom';

import './styles.scss';

function Home() {
  return (
    <div id="container-parent">
      <main id="container-options" className="container-fluid container-table">
        <section id="container-split-theme" className="container-row">
          <Link className="container-content" to="/theme">
            <h2 className="section-title">Theme</h2>
          </Link>
        </section>
        <section id="container-split-decade" className="container-row">
          <Link className="container-content" to="/decade">
            <h2 className="section-title">Decade</h2>
          </Link>
        </section>
        <div id="table-circle" className="table-overlay">
          <div className="table-overlay-text">OR</div>
        </div>
      </main>
    </div>
  );
}

export default Home;
