import {
  SET_THEME,
  SET_DECADE,
  RESET_JOURNEY,
  GET_ASSETS_ASYNC,
  GET_ASSETS_SUCCESS,
  GET_ASSETS_ERROR,
  SET_API_ENVIRONMENT,
  TOGGLE_MORE_INFO_MODAL,
  TOGGLE_INFO_ABOUT_MODAL,
  ECHO_ANALYTICS_INITIALISE,
  SET_CURRENT_FILE_ID,
  UPDATE_FAVOURITES,
} from './actionTypes';

export default (state, action) => {
  switch (action.type) {
    case SET_THEME:
      return {
        ...state,
        theme: action.theme,
      };
    case SET_DECADE:
      return {
        ...state,
        decade: action.decade,
      };
    case SET_CURRENT_FILE_ID:
      return {
        ...state,
        currentFileId: action.fileId,
      };
    case UPDATE_FAVOURITES:
      window.localStorage.setItem('favourites-video', JSON.stringify(action.newFavouritesFileIds.video));
      window.localStorage.setItem('favourites-audio', JSON.stringify(action.newFavouritesFileIds.audio));
      window.localStorage.setItem('favourites-images', JSON.stringify(action.newFavouritesFileIds.images));

      return {
        ...state,
        favouritesFileIds: action.newFavouritesFileIds,
      };
    case TOGGLE_MORE_INFO_MODAL:
      return {
        ...state,
        moreInfoModalOpen: action.toggleModal,
      };
    case TOGGLE_INFO_ABOUT_MODAL:
      return {
        ...state,
        infoAboutModalOpen: action.toggleModal,
      };
    case GET_ASSETS_ASYNC:
      return {
        ...state,
        loadingResults: action.loadingResults,
      };
    case GET_ASSETS_SUCCESS:
      return {
        ...state,
        resultList: action.resultList,
        loadingResults: false,
      };
    case GET_ASSETS_ERROR:
      return {
        ...state,
        error: action.err,
        loadingResults: false,
      };
    case SET_API_ENVIRONMENT:
      return {
        ...state,
        environment: action.environment,
      };
    case RESET_JOURNEY:
      return {
        ...action.initialState,
      };
    case ECHO_ANALYTICS_INITIALISE:
      return {
        ...state,
        echo: action.echo,
      };
    default:
      return state;
  }
};
