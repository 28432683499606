import React, { Component } from 'react';
import Router from './Router';
import { AppProvider } from './contexts/AppContext';

import RemArcTitleBar from './Components/RemArcTitleBar/RemArcTitleBar';

class App extends Component {
  componentDidMount() {
    this.performHostRedirect();
  }

  performHostRedirect() {
    const parts = window.location.host.split('.');
    if (parts[0] === 'www') {
      // Remove the www subdomain from the bbcrewind.co.uk host
      parts.splice(0, 1);
      window.location = `${window.location.protocol}//${parts.join('.')}/`;
    }
  }

  render() {
    return (
      <AppProvider>
        {/* <center><div style={{color: 'white', fontSize: '2em', paddingTop: '40%'}}>
            This site is currently down for maintenance.
          </div></center> */}
        <RemArcTitleBar />
        <Router />
      </AppProvider>
    );
  }
}

export default App;
