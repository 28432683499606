import React from 'react';
import {
  Container, Col, Row, Button,
} from 'react-bootstrap';
import FormatSelector from '../../Components/FormatSelector/FormatSelector';
import { useAppContext } from '../../contexts/AppContext';

import './styles.scss';

const DECADES = [
  '1930s',
  '1940s',
  '1950s',
  '1960s',
  '1970s',
  '1980s',
  '1990s',
  '2000s',
];

function DecadeSelection() {
  const { state: { decade }, setDecade } = useAppContext();

  const handleSelection = (selection) => {
    setDecade(selection);

    setTimeout(() => {
      document.getElementById("decade-format-selector").scrollIntoView({
        behavior: 'smooth',
        block: 'end'
      });
    }, 200);
  };

  return (
    <Container fluid role="main">
      <div className="decade-bg-overlay" />
      <Row className="page-title">
        <h2>Decade</h2>
      </Row>
      <Row>
        {
          DECADES.map((thisDecade) => (
            <Col xs={12} sm={6} md={6} lg={3} xl={3} key={thisDecade}>
              <Button className="btn-decade btn-default" onClick={() => handleSelection(thisDecade)}>
                <span>{thisDecade}</span>
              </Button>
            </Col>
          ))
        }
      </Row>
      <Row className="justify-content-md-center">
        <Col xs={12} sm={6} md={6} lg={3} xl={3}>
          <Button className="btn-decade btn-default" onClick={() => handleSelection('favourites')}>
            <span>Favourites</span>
          </Button>
        </Col>
        <Col xs={12} sm={6} md={6} lg={3} xl={3}>
          <Button className="btn-decade btn-back btn-default" href="/">
            <span>Back</span>
          </Button>
        </Col>
      </Row>
      <br />
      <div id="decade-format-selector">
        {
          decade && (
            <FormatSelector />
          )
        }
      </div>
    </Container>
  );
}

export default DecadeSelection;
