import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import { useAppContext } from '../../contexts/AppContext';
import GelIcon from '../GelIcon';
import { CDN_URL } from '../../constants';

import './styles.scss';

function SmpPlayer({ asset, playerIndex, slideIndex }) {
  // Used to store when the player is loaded and ready
  const [playerLoaded, setPlayerLoaded] = useState(false);

  // Used to store when the player is playing media
  const [isPlaying, setIsPlaying] = useState(false);

  // Used to store if an audio replay control button is pressed
  const [replayPressed, setReplayPressed] = useState(false);

  // Used to keep a reference to the SMP object
  const [smpRef, setSmpRef] = useState(null);
  const playerElement = useRef(null);

  const { echoTrack } = useAppContext();

  const buildPlayerConfig = () => {
    const assetFilename = asset.assetType === 'video' ? asset.files.mp4Filename : asset.files.mp3Filename;

    return {
      product: 'news',
      autoplay: true,
      ui: {
        buffer: {
          enabled: true,
        },
        colour: '#3A549C',
        controls: {
          always: false,
          availableOnMediaEnded: true,
          spaceControlsPlayback: true,
        }
      },
      playlistObject: {
        items: [{
          href: `${CDN_URL}/${asset.assetType}/${assetFilename}`,
        }],
        holdingImageURL: `${CDN_URL}/${asset.assetType}/${asset.files.imgFilename}`,
      },
    };
  };

  const playCallback = () => {
    echoTrack('assetpage', { action: `smp_played_${asset.assetType}`, data: { assetId: asset.id } }, 'click');
  };

  const pauseCallback = (e) => {
    setIsPlaying(false);

    if (!e.ended) {
      echoTrack('assetpage', { action: `smp_paused_${asset.assetType}`, data: { assetId: asset.id } }, 'click');
    }
  };

  const endedCallback = () => {
    setIsPlaying(false);
    echoTrack('assetpage', { action: `smp_completed_${asset.assetType}`, data: { assetId: asset.id } }, 'click');
  };

  const loadBump = () => {
    window.require(['bump-4'], (Bump) => {
      const playerConfig = buildPlayerConfig();
      const smp = Bump.player(playerElement.current, playerConfig);

      // Store state reference to this SMP player
      setSmpRef(smp);

      smp.bind('playlistLoaded', () => setPlayerLoaded(true));
      smp.bind('play', playCallback);
      smp.bind('pause', pauseCallback);
      smp.bind('ended', endedCallback);
      smp.bind('playing', () => {
        setIsPlaying(true);
      });
      smp.load();
    });
  };

  const playMedia = () => {
    smpRef.play();
    setIsPlaying(true);
  };

  const pauseMedia = () => {
    smpRef.pause();
    setIsPlaying(false);
  };

  const replayMedia = () => {
    smpRef.currentTime(0);
  };

  useEffect(() => {
    // Load the player if the user hasn't navigated to this slide before
    if ((playerIndex === slideIndex) && !playerLoaded) {
      loadBump();
    }

    // If the player has loaded already, then the user is going back to this slide
    // so autoplay the video
    if ((playerIndex === slideIndex) && playerLoaded) {
      playMedia();
    }

    // Pause the player if moving to a different slide
    if ((playerIndex !== slideIndex) && playerLoaded) {
      smpRef.pause();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [playerIndex, slideIndex]);

  return (
    <div className="smp-container">
      {(asset.assetType === 'video') &&
        <button className={`smp-overlay-button -${asset.assetType} -${!isPlaying ? 'paused' : 'playing'}`} onClick={() => !isPlaying ? playMedia() : pauseMedia()}>
          {
            !isPlaying && (
              <div className="smp-playback-controls">
                <div className="play-control-button">
                  <GelIcon name="play" size={140} />
                </div>
              </div>
            )
          }
          <span>{!isPlaying ? 'Play' : 'Pause'}</span>
        </button>
      }
      {
        asset.assetType === 'audio' && (
          <div className="smp-playback-controls">
            <div className="smp-audio-overlay" />
            <img src={`${CDN_URL}/${asset.assetType}/${asset.files.imgFilename}`} alt={asset.metadata} />
            {isPlaying ? (
              <button type="button" onClick={pauseMedia}>
                <GelIcon name="pause" size={140} />
              </button>
            )
              : (
                <button type="button" onClick={playMedia}>
                  <GelIcon name="play" size={140} fill={'#FFFFFF'} />
                </button>
              )}
            <button
              type="button"
              onClick={replayMedia}
              onMouseDown={() => setReplayPressed(true)}
              onTouchStart={() => setReplayPressed(true)}
              onMouseUp={() => setReplayPressed(false)}
              onTouchEnd={() => setReplayPressed(false)}
            >
              <GelIcon name="back-to-start" size={140} fill={replayPressed ? '#23FF0F' : '#FFFFFF'} />
            </button>
          </div>
        )
      }
      <div className="smp-player" ref={playerElement} />
    </div>
  );
}

SmpPlayer.propTypes = {
  asset: PropTypes.shape({
    id: PropTypes.string,
    assetType: PropTypes.string,
    metadata: PropTypes.string,
    files: PropTypes.shape({
      mp4Filename: PropTypes.string,
      mp3Filename: PropTypes.string,
      imgFilename: PropTypes.string,
    })
  }),
  playerIndex: PropTypes.number,
  slideIndex: PropTypes.number,
}

export default SmpPlayer;