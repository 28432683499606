import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { useAppContext } from '../../contexts/AppContext';

function NoResultsModal() {
  const { resetJourney } = useAppContext();

  return (
    <Modal show centered id="no-results-modal">
      <Modal.Header>
        <Modal.Title style={{ margin: 'auto' }}>No Results</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>There were no results found for your selection.</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" size="lg" href="/" onClick={resetJourney}>
          Start Again
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default NoResultsModal;