import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Button } from 'react-bootstrap';

import { useAppContext } from '../../contexts/AppContext';
import { CDN_URL } from '../../constants';

function MoreInfoModal({ item, assetType }) {
  const { toggleMoreInfoModal } = useAppContext();

  const downloadAsset = () => {
    if (assetType === 'images') {
      window.open(`${CDN_URL}/images/${item.files.imgFilename}?download`);
    }
    if (assetType === 'audio') {
      window.open(`${CDN_URL}/audio/${item.files.mp3Filename}?download`);
    }
    if (assetType === 'video') {
      window.open(`${CDN_URL}/video/${item.files.mp4Filename}?download`);
    }
  };

  return (
    <Modal show onHide={() => toggleMoreInfoModal(false)} id="more-info-modal" centered>
      <Modal.Header closeButton>
        <Modal.Title style={{ paddingLeft: '30px' }}>More Information</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{item.metadata}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="dark" onClick={downloadAsset}>
          Download
        </Button>
        <Button variant="secondary" size="sm" onClick={() => toggleMoreInfoModal(false)}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

MoreInfoModal.propTypes = {
  item: PropTypes.shape({
    files: PropTypes.shape({
      imgFilename: PropTypes.string,
      mp3Filename: PropTypes.string,
      mp4Filename: PropTypes.string,
    }),
    metadata: PropTypes.string,
  }),
  assetType: PropTypes.string,
}

export default MoreInfoModal;
