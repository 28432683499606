export const SET_THEME = 'SET_THEME';
export const SET_DECADE = 'SET_DECADE';

export const GET_ASSETS_ASYNC = 'GET_ASSETS_ASYNC';
export const GET_ASSETS_SUCCESS = 'GET_ASSETS_SUCCESS';
export const GET_ASSETS_ERROR = 'GET_ASSETS_ERROR';

export const TOGGLE_MORE_INFO_MODAL = 'TOGGLE_MORE_INFO_MODAL';
export const TOGGLE_INFO_ABOUT_MODAL = 'TOGGLE_INFO_ABOUT_MODAL';
export const RESET_JOURNEY = 'RESET_JOURNEY';

export const ECHO_ANALYTICS_INITIALISE = 'ECHO_ANALYTICS_INITIALISE';

export const SET_API_ENVIRONMENT = 'SET_API_ENVIRONMENT';

export const SET_CURRENT_FILE_ID = 'SET_CURRENT_FILE_ID';

export const UPDATE_FAVOURITES = 'UPDATE_FAVOURITES';
