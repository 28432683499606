import React, { useState, useEffect } from 'react';

import {
  Navbar, Nav, Image, Button,
} from 'react-bootstrap';

import { useAppContext } from '../../contexts/AppContext';
import InfoAboutModal from '../InfoAboutModal';
import GelIcon from '../GelIcon';

import './styles.scss';

function RemArcTitleBar() {
  const [modalType, setModalType] = useState(null);
  const [isFavourite, setIsFavourite] = useState(false);

  const {
    state: {
      infoAboutModalOpen, environment, resultList, currentFileId, favouritesFileIds,
    }, resetJourney, toggleInfoAboutModal, setEnvironment, updateFavourites,
  } = useAppContext();

  const showRestartButton = window.location.pathname.split('/').length > 2 && (window.location.pathname.includes('theme') || window.location.pathname.includes('decade'));

  // Show info modal on first site load when component mounts
  useEffect(() => {
    if (!JSON.parse(window.localStorage.getItem('remarc-loaded'))) {
      setModalType('info');
      toggleInfoAboutModal(true);
    }
    setEnvironment();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Check for favourite status when item changes
  useEffect(() => {
    if (favouritesFileIds.video.includes(currentFileId)
      || favouritesFileIds.audio.includes(currentFileId)
      || favouritesFileIds.images.includes(currentFileId)) {
      setIsFavourite(true);
    } else {
      setIsFavourite(false);
    }
  }, [currentFileId, favouritesFileIds]);

  const addFavourite = () => {
    const newFavouritesFileIds = favouritesFileIds;
    const path = window.location.pathname.split('/');
    const format = path[path.length - 1];

    newFavouritesFileIds[format].push(currentFileId);

    updateFavourites(newFavouritesFileIds);
    setIsFavourite(true);
  };

  const removeFavourite = () => {
    const newFavouritesFileIds = favouritesFileIds;
    const path = window.location.pathname.split('/');
    const format = path[path.length - 1];

    newFavouritesFileIds[format].splice(newFavouritesFileIds[format].indexOf(currentFileId), 1);

    updateFavourites(newFavouritesFileIds);
    setIsFavourite(false);
  };

  return (
    <>
      <Navbar variant="dark" className="navbar-inverse" id="remarc-title-bar">
        <div className="navbar-header">
          <Navbar.Brand href="/">
            <h1>
              <Image width="55px" className="navbar-brand-logo" src="../images/bbc-blocks-logo.svg" title={`${process.env.REACT_APP_NAME} v${process.env.REACT_APP_VERSION}`} />
              &nbsp;Remarc
              {environment !== 'prod' && (
                <span style={{ color: 'red' }}>{` ${environment.toUpperCase()}`}</span>
              )}
            </h1>
          </Navbar.Brand>
          {
            showRestartButton && (
              <Button className="navbar-start-button" variant="light" href="/" onClick={() => resetJourney()}>Start Again</Button>
            )
          }
        </div>
        <Nav className="main-nav">
          <Nav.Item>
            <Button variant="link" onClick={() => { setModalType('info'); toggleInfoAboutModal(true); }}>
              Information
            </Button>
          </Nav.Item>
          {resultList.length ? (
            <Nav.Item>
              {isFavourite ? (
                <Button variant="link" onClick={removeFavourite} style={{ paddingRight: '10px' }}>
                  Remove from Favourites
                </Button>
              ) : (
                <Button variant="link" onClick={addFavourite} style={{ paddingRight: '10px' }}>
                  Add to Favourites
                </Button>
              )}
              <GelIcon name="favourite" fill={isFavourite ? 'yellow' : 'darkgrey'} />
            </Nav.Item>
          ) : (
            <Nav.Item>
              <Button variant="link" onClick={() => { setModalType('about'); toggleInfoAboutModal(true); }}>
                About
              </Button>
            </Nav.Item>
          )}
        </Nav>
      </Navbar>
      {
        infoAboutModalOpen && <InfoAboutModal modalType={modalType} />
      }
    </>
  );
}

export default RemArcTitleBar;
