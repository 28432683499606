/* eslint-disable no-plusplus */
import React, {
  useState, useEffect, useRef,
} from 'react';
import { withRouter } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import PropTypes from 'prop-types';

import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

import { useAppContext } from '../../contexts/AppContext';
import GelIcon from '../../Components/GelIcon';
import SmpPlayer from '../../Components/SmpPlayer';
import MoreInfoModal from '../../Components/MoreInfoModal';
import { CDN_URL } from '../../constants';
import NoResultsModal from '../../Components/NoResultsModal';

import './carouselStyles.scss';

const mainResponsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 414 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 414, min: 0 },
    items: 1,
  },
};

const thumbResponsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
    slidesToSlide: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 414 },
    items: 3,
    slidesToSlide: 3,
  },
  mobile: {
    breakpoint: { max: 414, min: 0 },
    items: 3,
    slidesToSlide: 3,
  },
};

const CustomLeftArrow = ({ onClick, size, showText }) => (
  <button type="button" className="react-multiple-carousel__arrow react-multiple-carousel__arrow--left" onClick={onClick}>
    <GelIcon name="prev" size={size} />
    {showText && <span>Prev</span>}
  </button>
);

CustomLeftArrow.propTypes = {
  onClick: PropTypes.func,
  size: PropTypes.number,
  showText: PropTypes.bool,
};

const CustomRightArrow = ({ onClick, size, showText }) => (
  <button type="button" className="react-multiple-carousel__arrow react-multiple-carousel__arrow--right" onClick={onClick}>
    {showText && <span>Next</span>}
    <GelIcon name="next" size={size} />
  </button>
);

CustomRightArrow.propTypes = {
  onClick: PropTypes.func,
  size: PropTypes.number,
  showText: PropTypes.bool,
};

function AssetPage({ match: { params } }) {
  const mainCarouselRef = useRef();
  const thumbCarouselRef = useRef();

  // Keep these in local state for now
  const [currentItem, setCurrentItem] = useState(0);
  const [thumbnailIndex, setThumbnailIndex] = useState(0);

  // Take from global state
  const {
    state: { resultList, moreInfoModalOpen, loadingResults },
    getAssets,
    toggleMoreInfoModal,
    echoTrack,
    setCurrentFileId,
  } = useAppContext();

  useEffect(() => {
    getAssets(params.mode, params.modeValue, params.assetType);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Track results when updated
  useEffect(() => {
    if (resultList.length) {
      const resultListIds = resultList.map((result) => result.id);
      echoTrack('assetpage', {
        action: 'assets_loaded',
        data: {
          assetIds: resultListIds, mode: params.mode, modeValue: params.modeValue, assetType: params.assetType,
        },
      }, 'click');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resultList]);

  // Track image info on successful view
  useEffect(() => {
    if (resultList[currentItem] && params.assetType === 'images') {
      echoTrack('assetpage', { action: 'viewed_image', data: { assetId: resultList[currentItem].id } }, 'click');
    }

    // Update the state fileId to current asset
    if (resultList[currentItem]) {
      setCurrentFileId(resultList[currentItem].fileId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resultList, currentItem]);

  const reloadResults = () => {
    setCurrentItem(0);
    setThumbnailIndex(0);
    getAssets(params.mode, params.modeValue, params.assetType);
  };

  let moreButtonText = 'More'
  switch (params.assetType) {
    case 'images':
      moreButtonText += ' Images'; break;
    case 'audio':
      moreButtonText += ' Sound Clips'; break;
    case 'video':
      moreButtonText += ' Film Clips'; break;
    default:
      break;
  }

  // Store length of results
  const resultLength = resultList.length;

  // Work out if we are at the end of the carousel
  // -3 since the thumbnail carousel is in groups of 3
  // Don't show the button if in Favourites page
  const showMoreResultsButton = (resultList.length - 3) === thumbnailIndex && params.modeValue !== 'favourites';

  return (
    <main>
      {!loadingResults && resultLength && (
        <button type="button" className="asset-info-button" onClick={() => toggleMoreInfoModal(true)}>
          <GelIcon name="info" size={45} />
        </button>
      )}
      <Container fluid className="container-dark">
        {loadingResults ? (
          <div className="loading-icon">
            <GelIcon name="loading" size={50} />
          </div>
        ) : (
          <>
            {resultLength > 0 ? (
              <>
                <Carousel
                  activeSlide={currentItem}
                  ref={mainCarouselRef}
                  swipeable={false}
                  draggable={false}
                  responsive={mainResponsive}
                  keyBoardControl
                  containerClass={`main-carousel ${params.assetType}-carousel`}
                  afterChange={(previousSlide, { currentSlide }) => {
                    // Work out direction of carousel movement
                    const movingRight = currentSlide > previousSlide;
                    const movingLeft = !movingRight;

                    // If moving right...
                    if (movingRight) {
                      // Check if we should move the thumbnail-carousel along
                      // when the user reaches the end of the group of 3
                      const moveThumbCarousel = (currentSlide) % 3 === 0;

                      if (moveThumbCarousel) {
                        thumbCarouselRef.current.goToSlide(currentSlide);
                      }
                    }

                    // If moving left...
                    if (movingLeft) {
                      // Check if we should move the thumbnail-carousel along
                      // when the user reaches the end of the group of 3
                      const moveThumbCarousel = (currentSlide) % 3 === 2;

                      if (moveThumbCarousel) {
                        // minus 2 to take the carousel back to the start of the group of 3
                        thumbCarouselRef.current.goToSlide(currentSlide - 2);
                      }
                    }

                    // Set the currentSlide in state so that the 'More Info' modal shows the correct metadata
                    setCurrentItem(currentSlide);
                  }}
                  customLeftArrow={<CustomLeftArrow size={60} />}
                  // Carousel index is NOT zero-based, so we need to +1 to get the correct index
                  customRightArrow={((currentItem + 1) < resultLength) ? <CustomRightArrow size={60} /> : <></>}
                >
                  {
                    // Image Carousel
                    params.assetType === 'images' && resultList.map((item) => (
                      <div key={item.id}>
                        <img className="slide-image" src={`${CDN_URL}/images/${item.files.imgFilename}`} alt={item.metadata} />
                      </div>
                    ))
                  }
                  {
                    // Video or Audio carousel
                    (params.assetType === 'video' || params.assetType === 'audio') && resultList.map((item, index) => (
                      <SmpPlayer key={item.id} asset={item} playerIndex={index} slideIndex={currentItem} />
                    ))
                  }
                </Carousel>
                <Row>
                  <Col xs={1} md={1} lg={1} xl={1} />
                  <Col xs={10} md={10} lg={10} xl={10}>
                    <div className="thumbnail-carousel-button-container">
                      { thumbnailIndex !== 0 && (
                        <button type="button" className="thumbnail-carousel-button button-left" onClick={() => thumbCarouselRef.current.previous()}>
                          <GelIcon name="prev" size={30} />
                          <span>Prev</span>
                        </button>
                      )}
                      { (resultLength > 3 && !showMoreResultsButton) && (
                        <button type="button" className="thumbnail-carousel-button button-right" onClick={() => thumbCarouselRef.current.next()}>
                          <span>Next</span>
                          <GelIcon name="next" size={30} />
                        </button>
                      )}
                      {
                        showMoreResultsButton && (
                          <button type="button" className="thumbnail-carousel-button button-more" onClick={reloadResults}>
                            <GelIcon name="refresh" size={30} />
                            <span>{moreButtonText}</span>
                          </button>
                        )
                      }
                    </div>
                    <Carousel
                      ref={thumbCarouselRef}
                      swipeable={false}
                      draggable={false}
                      arrows={false}
                      responsive={thumbResponsive}
                      keyBoardControl
                      renderButtonGroupOutside
                      containerClass={`thumbnail-carousel${resultLength < 3 ? ' center-carousel' : ''}`}
                      afterChange={(previousSlide, { currentSlide }) => {
                        setThumbnailIndex(currentSlide);
                      }}
                    >
                      {
                        resultList.map((item, index) => (
                          <button
                            key={item.id}
                            type="button"
                            className={`carousel-thumb-button${currentItem === index ? ' active-image' : ''}`}
                            onClick={() => mainCarouselRef.current.goToSlide(index)}
                          >
                            <img className="selector-image" src={`${CDN_URL}/${params.assetType}/${item.files.imgFilename}`} alt={item.metadata} />
                          </button>
                        ))
                      }
                    </Carousel>
                  </Col>
                  <Col xs={1} md={1} lg={1} xl={1} />
                </Row>
              </>
            ) : (
              <NoResultsModal />
            )}
          </>
        )}
      </Container>
      {
        moreInfoModalOpen && <MoreInfoModal item={resultList[currentItem]} assetType={params.assetType} />
      }
    </main>
  );
}

AssetPage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      mode: PropTypes.string,
      modeValue: PropTypes.string,
      assetType: PropTypes.string,
    })
  })
}

export default withRouter(AssetPage);
