import React, { useReducer, createContext, useEffect, useMemo, useContext } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import AppReducer from './appReducer';
import {
  SET_THEME,
  SET_DECADE,
  RESET_JOURNEY,
  GET_ASSETS_ASYNC,
  GET_ASSETS_SUCCESS,
  GET_ASSETS_ERROR,
  TOGGLE_MORE_INFO_MODAL,
  TOGGLE_INFO_ABOUT_MODAL,
  ECHO_ANALYTICS_INITIALISE,
  SET_API_ENVIRONMENT,
  SET_CURRENT_FILE_ID,
  UPDATE_FAVOURITES,
} from './actionTypes';

import { API_URL, VERSION, ENVIRONMENT } from '../constants';

const initialState = {
  theme: '',
  decade: '',
  favouritesFileIds: {
    video: JSON.parse(window.localStorage.getItem('favourites-video')) || [],
    audio: JSON.parse(window.localStorage.getItem('favourites-audio')) || [],
    images: JSON.parse(window.localStorage.getItem('favourites-images')) || [],
  },
  currentFileId: '',
  resultList: [],
  error: '',
  moreInfoModalOpen: false,
  infoAboutModalOpen: false,
  loadingResults: true,
  environment: '',
  echo: null,
};

export const AppContext = createContext(initialState);

// Provider component
export const AppProvider = ({ children }) => {
  const [state, dispatch] = useReducer(AppReducer, initialState);

  // Actions
  function setTheme(theme) {
    dispatch({
      type: SET_THEME,
      theme,
    });
  }

  function setDecade(decade) {
    dispatch({
      type: SET_DECADE,
      decade,
    });
  }

  function setCurrentFileId(fileId) {
    dispatch({
      type: SET_CURRENT_FILE_ID,
      fileId,
    });
  }

  function updateFavourites(newFavouritesFileIds) {
    dispatch({
      type: UPDATE_FAVOURITES,
      newFavouritesFileIds,
    });
  }

  function toggleMoreInfoModal(toggleModal) {
    dispatch({
      type: TOGGLE_MORE_INFO_MODAL,
      toggleModal,
    });
  }

  function toggleInfoAboutModal(toggleModal) {
    dispatch({
      type: TOGGLE_INFO_ABOUT_MODAL,
      toggleModal,
    });
  }

  function getAssets(mode, modeValue, assetType) {
    const { favouritesFileIds } = state;

    dispatch({
      type: GET_ASSETS_ASYNC,
      loadingResults: true,
    });

    if (modeValue === 'favourites') {
      let assetFileIds;

      switch (assetType) {
        case 'video':
          assetFileIds = favouritesFileIds.video;
          break;
        case 'audio':
          assetFileIds = favouritesFileIds.audio;
          break;
        case 'images':
          assetFileIds = favouritesFileIds.images;
          break;
        default:
          assetFileIds = [];
          break;
      }

      fetch(`${API_URL}/api/assets/favourites`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ fileIds: assetFileIds }),
      })
        .then((res) => res.json())
        .then((data) => dispatch({
          type: GET_ASSETS_SUCCESS,
          resultList: data,
        }))
        .catch((err) => dispatch({
          type: GET_ASSETS_ERROR,
          err,
        }));
    } else {
      fetch(`${API_URL}/api/assets/${mode}/${modeValue.trim().replace(/\s/g, "_").toLowerCase()}/${assetType}`)
        .then((res) => res.json())
        .then((data) => dispatch({
          type: GET_ASSETS_SUCCESS,
          resultList: data,
        }))
        .catch((err) => dispatch({
          type: GET_ASSETS_ERROR,
          err,
        }));
    }
  }

  function setEnvironment() {
    fetch(`${API_URL}/api`)
      .then((res) => res.json())
      .then((data) => dispatch({
        type: SET_API_ENVIRONMENT,
        environment: data.environment,
      }))
      .catch((err) => console.error('Unable to get environment from API', err));
  }

  function resetJourney() {
    dispatch({
      type: RESET_JOURNEY,
      initialState,
    });
  }

  function echoTrack(pageName, eventData = null, actionName) {
    if (!state.echo) {
      // eslint-disable-next-line
      console.warn('Echo still initialising.');
      return false;
    }

    // If an 'actionName' is specified (click, submit etc) but no eventData.action, don't fire
    if ((actionName && !eventData) || (actionName && !eventData.action)) return false;

    return actionName
      ? state.echo.userActionEvent(
        // actionName = 'click', 'submit' etc
        actionName,
        // action = 'clicked_asset', 'submit_report_form' etc
        eventData.action,
        {
          // container = page name e.g. 'home', 'searchresults' etc
          container: pageName,
          // metadata = data about the 'action' e.g. '{"assetId": "xxxx", "title": "Asset Title"}'. Serialized.
          metadata: eventData.data ? JSON.stringify(eventData.data) : null,
        },
      )
      : state.echo.viewEvent(
        // page name
        `remarc.${pageName}`,
        // additional event data, if any
        eventData && {
          event_data: _.isObject(eventData) ? JSON.stringify(eventData) : eventData,
        },
      );
  }

  function initialiseEchoAnalytics() {
    // Initialise Echo client, including default client data
    window.require(['echo-lib'], (EchoLib) => {
      const { Enums, ConfigKeys } = EchoLib; // Enums

      const echo = new EchoLib.EchoClient(
        'remarc', // App Name
        Enums.ApplicationType.WEB, // App Type
        {
          [ConfigKeys.ATI_ENABLED]: true,
          [ConfigKeys.DESTINATION]: ENVIRONMENT === 'production'
            ? Enums.Destinations.REWIND_PS
            : Enums.Destinations.REWIND_PS_TEST,
          [ConfigKeys.REPORTING_PROFILE]: 'GNL',
        },
      );

      echo.setProducer(Enums.Producers.OTHER);
      // set the version of the application
      echo.setAppVersion(VERSION);

      if (!state.echo) {
        dispatch({ type: ECHO_ANALYTICS_INITIALISE, echo });
      } else {
        dispatch(echoTrack('loadsite', 'load-success'));
      }
    });
  }

  // Init echo analytics when component mounts
  useEffect(() => {
    initialiseEchoAnalytics();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.echo]);

  const ctx = useMemo(() => ({
    state,
    setTheme,
    setDecade,
    setCurrentFileId,
    updateFavourites,
    resetJourney,
    toggleMoreInfoModal,
    toggleInfoAboutModal,
    getAssets,
    setEnvironment,
    echoTrack
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }), [state]);

  return (
    <AppContext.Provider value={ctx}>
      {children}
    </AppContext.Provider>
  );
};

AppProvider.propTypes = {
  children: PropTypes.array,
};

export function useAppContext() {
  return useContext(AppContext);
}
