import React from 'react';
import { Router, Route, Switch, Redirect } from 'react-router-dom';
import { createBrowserHistory } from 'history';

import Home from './Pages/Home/Home';
import ThemeSelection from './Pages/ThemeSelection/ThemeSelection';
import DecadeSelection from './Pages/DecadeSelection/DecadeSelection';
import AssetPage from './Pages/AssetPage/AssetPage';
import NotFound from './Pages/NotFound/NotFound';

function AppRouter() {
  const history = createBrowserHistory({ keyLength: 6 });

  return (
    <Router history={history}>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/theme" component={ThemeSelection} />
        <Route exact path="/decade" component={DecadeSelection} />
        <Redirect from="/remarc" to="/" />

        {/* :mode = (theme, decade), :modeValue = (animals, 1940s), :assetType (video | audio | images) */}
        <Route exact path="/:mode(theme|decade)/:modeValue/:assetType" component={AssetPage} />

        {/* NotFound is a 404 page for unmatched routes */}
        <Route path={null} component={NotFound} />
      </Switch>
    </Router>
  );
}

export default AppRouter;
